<template>
    <div class="wrap">
        <el-page-header @back="$router.back()" content="订单详情" class="margin-bottom margin-top-xs"></el-page-header>
        <div class="form_box">
            <div class="subItem">
                <div class="itemize">基本信息</div>
                <div class="row">
                    <div class="col">
                        <div class="col-label">活动名称：</div>
                        <div class="col-value">{{ form.activity_title }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="col-label">订单号：</div>
                        <div class="col-value">{{ form.order_no }}</div>
                    </div>
                    <div class="col">
                        <div class="col-label">下单时间：</div>
                        <div class="col-value">
                            {{ $util.date.format(form.created_at, 'yyyy-MM-dd hh:mm:ss') }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="col-label">买家：</div>
                        <div class="col-value">{{ form.buyer_name }}</div>
                    </div>
                    <div class="col">
                        <div class="col-label">买家电话：</div>
                        <div class="col-value">{{ form.buyer_mobile }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="col-label">支付金额：</div>
                        <div class="col-value">{{ `￥${form.pay_money}` }}</div>
                    </div>
                    <div class="col">
                        <div class="col-label">支付状态：</div>
                        <div class="col-value">
                            <el-button type="success" size="mini" plain v-if="form.pay_status === 2">已支付</el-button>
                            <el-button type="danger" size="mini" plain v-else-if="form.pay_status === 1">未支付</el-button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="col-label">订单状态：</div>
                        <div class="col-value">
                            <el-button type="success" size="mini" plain v-if="form.order_status === 2">已结算</el-button>
                            <el-button type="danger" size="mini" plain v-else-if="form.order_status === 1">未结算</el-button>
                        </div>
                    </div>
                    <div class="col">
                        <div class="col-label">分享佣金：</div>
                        <div class="col-value">{{ `￥${form.commission}` }}</div>
                    </div>
                </div>
            </div>
            <div class="subItem">
                <div class="itemize">月度商品</div>
                <div class="goods-list">
                    <div class="goods-card">
                        <el-image style="width: 200px; height: 200px" :src="monthGoods.goods_image" fit="fit" />
                        <label class="goods-name">{{ monthGoods.goods_name }}</label>
                    </div>
                </div>
            </div>
            <div class="subItem">
                <div class="itemize">季度商品</div>
                <div class="goods-list">
                    <div class="goods-card" v-for="(item, index) in goodsList" :key="index">
                        <el-image style="width: 200px; height: 200px" :src="item.image" fit="fit" />
                        <label class="goods-name">{{ item.name }}</label>
                    </div>
                </div>
            </div>
            <div class="subItem">
                <div class="itemize">月度商品核销记录</div>
                <el-table :data="form.month_goods_list" v-loading="loading" style="max-width:1100px">
                    <el-table-column type="index" width="50"></el-table-column>
                    <el-table-column label="核销截止时间">
                        <template slot-scope="scope">
                            {{ $util.date.format(scope.row.end_time * 1000, 'yyyy-MM-dd hh:mm:ss') }}
                        </template>
                    </el-table-column>
                    <el-table-column label="核销时间">
                        <template slot-scope="scope">
                            {{ scope.row.check_time ? $util.date.format(scope.row.check_time * 1000, 'yyyy-MM-dd hh:mm:ss') : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="核销状态" align="center">
                        <template slot-scope="scope">
                            <el-button type="success" size="mini" plain v-if="scope.row.check_status === 2">已核销</el-button>
                            <el-button type="warning" size="mini" plain v-else-if="scope.row.check_status === 1">未核销</el-button>
                            <el-button type="danger" size="mini" plain v-else-if="scope.row.check_status === -1">已过期</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="operate" label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="primary" size="mini" @click="orderCheck(scope.row.id)" v-if="scope.row.check_status === 1">核销</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="subItem">
                <div class="itemize">季度商品核销记录</div>
                <el-table :data="form.season_goods_list" v-loading="loading" style="max-width:1100px">
                    <el-table-column type="index" width="50"></el-table-column>
                    <el-table-column label="核销截止时间">
                        <template slot-scope="scope">
                            {{ $util.date.format(scope.row.end_time * 1000, 'yyyy-MM-dd hh:mm:ss') }}
                        </template>
                    </el-table-column>
                    <el-table-column label="核销时间">
                        <template slot-scope="scope">
                            {{ scope.row.check_time ? $util.date.format(scope.row.check_time * 1000, 'yyyy-MM-dd hh:mm:ss') : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="核销状态" align="center">
                        <template slot-scope="scope">
                            <el-button type="success" size="mini" plain v-if="scope.row.check_status === 2">已核销</el-button>
                            <el-button type="warning" size="mini" plain v-else-if="scope.row.check_status === 1">未核销</el-button>
                            <el-button type="danger" size="mini" plain v-else-if="scope.row.check_status === -1">已过期</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="operate" label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="primary" size="mini" @click="orderCheck(scope.row.id)" v-if="scope.row.check_status === 1">核销</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    inject: ['reload'],
    data() {
        return {
            form: {},
            loading: false,
            monthGoods: {},
            goodsList: [],
        };
    },
    created() {
        this.getDetail();
    },
    methods: {
        getDetail: function() {
            this.loading = true;
            this.axios.post('/api/merchant/CarActivity/orderDetail', { id: this.$route.params.id }).then((res) => {
                this.loading = false;
                this.goodsList = JSON.parse(res.data.season_goods);
                this.monthGoods = JSON.parse(res.data.month_goods);
                this.form = res.data;
            });
        },
        orderCheck: function(id) {
            this.axios.post('/api/merchant/CarActivity/orderCheck', { id: id }).then((res) => {
                if (res.code != 200) {
                    this.$message({
                        type: 'error',
                        message: res.msg,
                    });
                } else {
                    this.reload();
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.form_box {
    .el-input,
    .el-textarea {
        width: 100%;
        max-width: 500px;
    }
}
.subItem {
    padding-left: 120px;
    padding-top: 60px;
    position: relative;

    .itemize {
        font-size: 16px;
        color: #333;
        padding-left: 20px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .itemize:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        width: 10px;
        height: 10px;
        background-color: #409eff;
    }
}

.row {
    margin-bottom: 22px;
    display: flex;
    font-size: 15px;
    font-weight: 400;
    color: #333;
    line-height: 28px;

    .col {
        display: inline-flex;

        .col-label {
            width: 120px;
            text-align: right;
        }

        .col-value {
            margin-left: 8px;
            margin-right: 20px;
            width: 260px;
        }
    }
}

.goods-list {
    display: flex;
    margin-bottom: 20px;

    .goods-card {
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 200px;
        box-shadow: 0 4px 16px 2px rgba(113, 128, 193, 0.15);
        transform: scale(1);
        transition: all 0.3s ease;
        padding-bottom: 10px;
        position: relative;

        &:hover {
            transform: scale(1.02);
        }

        .goods-name {
            margin: 10px;
            font-size: 14px;
            font-weight: 500;
            color: #999;
        }
    }
}
</style>
